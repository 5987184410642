<template>
    <div
        :class="{
            'vut-collapse--open': isOpen,
        }"
    >
        <BaseUtilCollapse
            v-model="isOpen"
            :collapsed-size="275"
        >
            <slot />
        </BaseUtilCollapse>

        <MaybeCollapse class="vut-collapse__actions">
            <BaseUiButton
                class="mt-4"
                variant="outlined"
                color="secondary"
                @click="handleClick"
            >
                {{ isOpen ? $t('labels.show_less') : $t('labels.show_more') }}
            </BaseUiButton>
        </MaybeCollapse>
    </div>
</template>

<script lang="tsx" setup>
import { BaseUtilCollapse } from '#components'
import type { FunctionalComponent } from 'vue'

const {
    once,
} = defineProps<{
    once?: boolean
}>()

const isOpen = ref<boolean>(false)
const isNotOpen = computed({
    get: () => !isOpen.value,
    set: (val) => isOpen.value = !val,
})

function handleClick() {
    if (once && typeof (document.activeElement as HTMLElement)?.blur === 'function') {
        (document.activeElement as HTMLElement).blur()
    }
    isOpen.value = !isOpen.value
}

const MaybeCollapse: FunctionalComponent = (_, ctx) => {
    if (once) {
        return <BaseUtilCollapse v-model={isNotOpen.value}>
            {ctx.slots.default?.()}
        </BaseUtilCollapse>
    }

    return <div>
        {ctx.slots.default?.()}
    </div>
}

</script>

<style lang="scss" scoped>

.vut-collapse__actions {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        height: 150%;
        transform: translateY(-100%);
        background: linear-gradient(rgb(255 255 255 / 0) 0%, $vut-c-white 100%);
        pointer-events: none;

        transition: opacity $vut-trans-time $vut-timing;
    }
}

.vut-collapse--open .vut-collapse__actions {
    &::before {
        opacity: 0;
    }
}

</style>
