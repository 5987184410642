<template>
    <CorePhotoswipeProvide ref="gallery" tag="div" class="vut-gallery">
        <!--  SLIDESHOW  -->
        <CoreSwiperWrapper>
            <div class="vut-gallery__slideshow">
                <CoreSwiper
                    :slides="productMedia"
                    :slide-gap="1"
                    type="gallery-main"
                    class="h-full"
                >
                    <template #slide="{ slide }">
                        <ProductGalleryItem :media="slide" />
                    </template>
                </CoreSwiper>

                <!--  NAVIGATION ARROWS  -->
                <SwiperNavigation class="hidden xl:block" />
            </div>
        </CoreSwiperWrapper>

        <!--  THUMBS  -->
        <ProductGalleryItem
            v-for="(thumb, index) in thumbnails.items"
            :key="index"
            :media="thumb"
            type="thumb"
            tag="button"
            @click="lightbox.openIndex(index)"
        />

        <ProductGalleryItem
            v-if="thumbnails.shouldTruncate"
            type="thumb"
            tag="button"
            class="vut-gallery__more group"
            @click="lightbox.openIndex(THUMBNAIL_COUNT - 1)"
        >
            <span class="vut-h3 text-c-black">
                {{ `+${thumbnails.remaining}` }}
            </span>

            <span class="vut-h6 !text-gray hidden underline group-hover:no-underline sm:flex">
                {{ $t('product.more_thumbs') }}
            </span>
        </ProductGalleryItem>
    </CorePhotoswipeProvide>
</template>

<script lang="ts" setup>

import { ProductImageModel } from '@simploshop-models/product-image.model'

const {
    productImages,
    productVideos,
    selectedVariation,
} = defineProps<{
    productImages: InstanceType<typeof ProductImageModel>[]
    productVideos: InstanceType<typeof ProductVideoModel>[]
    selectedVariation?: InstanceType<typeof ProductVariationModel> | null
}>()

const THUMBNAIL_COUNT = 5

const productMedia = computed(() => {
    const hasVariationImages = productImages.some(image => image.productVariationId === selectedVariation?.id)
    const images = productImages.filter(image => selectedVariation
        // show only variation images if variation is selected or, if the variation doesn't have images, show only default product images
        ? hasVariationImages ? image.productVariationId === selectedVariation.id : image.productVariationId === null
        // show default product images if no variation is selected
        : image.productVariationId === null
    )

    const defaultImages = images.filter(image => image.isDefault)
    const nonDefaultImages = images.filter(image => !image.isDefault)

    const media = [...defaultImages, ...nonDefaultImages, ...productVideos]

    if (media.length === 0) {
        media.push(ProductImageModel.create())
    }

    return media
})

const thumbnails = computed(() => {
    const shouldTruncate = productMedia.value.length > THUMBNAIL_COUNT

    return {
        remaining: shouldTruncate ? productMedia.value.length - THUMBNAIL_COUNT : 0,
        items: productMedia.value.slice(0, shouldTruncate ? THUMBNAIL_COUNT - 1 : THUMBNAIL_COUNT),
        shouldTruncate: shouldTruncate,
    }
})

const galleryEl = useTemplateRef<ComponentPublicInstance>('gallery')

const lightbox = usePhotoswipe({
    gallery: galleryEl,
    transition: 'fade',
})

</script>

<style lang="scss" scoped>

$thumbnail-count: 5;

.vut-gallery {
    display: grid;
    grid-template-columns: repeat($thumbnail-count, 1fr);
    grid-template-rows: auto auto;
    gap: 0.5rem;
}

.vut-gallery__slideshow {
    position: relative;

    aspect-ratio: 4 / 3;

    grid-column: 1 / -1;
    grid-row: 1 / #{$thumbnail-count};
}

.vut-gallery__more {
    display: flex;
    flex-direction: column;
    background: $vut-c-ivory;
    border: hr-line();
}

</style>
