<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <div>
        <!--  TOP PART (gallery + panel)  -->
        <BaseContainerContent
            v-if="product"
            class="vut-product__hero"
            tag="section"
            :padding="{ top: 'large', bottom: ['small', 'lg:large'] }"
        >
            <!--  Brand, Name, Description, Tags  -->
            <div class="vut-product__header">
                <div class="flex flex-wrap items-center gap-2">
                    <h1 class="vut-h1">
                        {{ product.getName(selectedVariation) }}
                    </h1>

                    <BaseUiButton
                        ref="productUrlCopyButton"
                        variant="bare"
                        size="auto"
                        color="secondary"
                        class="!p-2"
                        @click="copyProductUrl"
                    >
                        <template #leading>
                            <IconCopy />
                        </template>
                    </BaseUiButton>

                    <!--  TODO: make lazy?  -->
                    <UiTooltip
                        :reference="$refs.productUrlCopyButton"
                        placement="right"
                        :label="$t('product.copy_url')"
                    />
                </div>

                <p>
                    {{ product.shortDescription }}
                </p>
            </div>

            <div class="vut-product__gallery relative min-w-0">
                <!--  Gallery  -->
                <ProductGallery
                    :product-images="productImages"
                    :product-videos="productVideos"
                    :selected-variation="selectedVariation"
                />

                <div v-if="product.tags || product.brand"
                     class="absolute top-0"
                >
                    <template v-for="tag in product.tags" :key="tag.id"> <!-- TODO remove span and v-if !== 'Nové' -->
                        <BaseUiBadge
                            v-if="tag.label !== 'Nové'"
                            content-class="font-bold uppercase"
                            :custom-background-color="`#${tag.background_color}`"
                            :custom-text-color="`#${tag.text_color}`"
                        >
                            {{ tag.label }}
                        </BaseUiBadge>
                    </template>

                    <BaseUiBadge
                        v-if="product.brand"
                        :custom-background-color="getFacultyColor(product.brand.name)"
                        content-class="font-bold uppercase"
                    >
                        {{ product.brand.name }}
                    </BaseUiBadge>
                </div>
            </div>

            <!--  ACTIONS  -->
            <div class="vut-product__actions flex flex-col gap-11">
                <ProductPrice :product="product"
                              :selected-variation="selectedVariation"
                              type="full"
                />

                <div v-if="product.hasVariations" class="flex flex-col gap-7">
                    <ProductVariationSelector
                        v-for="property in variationProperties"
                        :key="property.id!"
                        v-model="selectedAttributes[property.id!]"
                        :available-variations="getAvailableVariations(property)"
                        :property="property"
                        :loading="variationsResponseStatus === 'pending' || variationsResponseStatus === 'idle'"
                        @change="handlePropertyChange"
                    />

                    <ProductVariationSelector
                        v-if="showFinalVariationSelector"
                        v-model:variation="selectedVariation"
                        :indistinguishable-variations="finalVariations"
                        :loading="variationsResponseStatus === 'pending' || variationsResponseStatus === 'idle'"
                    />
                </div>

                <div class="flex flex-wrap gap-x-7 gap-y-3">
                    <div class="w-full">
                        <ProductAvailability
                            :product="product"
                            :selected-variation="selectedVariation"
                        />

                        <span v-if="!product?.canBePurchased(selectedVariation)" class="vut-ff-primary normal-case text-black">
                            {{ $t('product.watchdog_info') }}
                        </span>
                    </div>

                    <UiCounter v-if="!product.isAddToCartButtonDisabled(selectedVariation)"
                               v-model="amount"
                               :max-value="product.getMaxPurchasableAmount(selectedVariation)"
                    />

                    <div class="flex flex-1 flex-row items-center md:flex-col">
                        <ProductAddToCartButton
                            :product="product"
                            :amount="amount"
                            type="detail"
                            class="w-full whitespace-nowrap"
                            size="large"
                            :variation="selectedVariation"
                        />

                        <ProductFavoriteButton
                            v-if="loggedIn"
                            :product="product"
                            class="!h-auto !w-fit"
                            :variation="selectedVariation"
                            type="full"
                        />
                    </div>
                </div>
            </div>
        </BaseContainerContent>

        <!--  BOTTOM PART (description + properties)  -->
        <BaseContainerContent
            v-if="product"
            class="vut-product__description"
            tag="section"
            :padding="{ top: ['small', 'lg:large'], bottom: 'large' }"
        >
            <div v-if="product.description || product.attachments?.length"
                 class="min-w-0 [grid-area:description]"
            >
                <!--  PRODUCT DESCRIPTION  -->
                <template v-if="product.description">
                    <h2 class="vut-h2 mb-5">
                        {{ $t('product.description') }}
                    </h2>

                    <!--  TODO: add a more robust description content length check  -->
                    <div
                        v-if="product.description.length < 300"
                        class="vut-wysiwyg-text"
                        v-html="product.description"
                    />
                    <UiCollapse
                        v-else
                        class="mb-8"
                        once
                    >
                        <div class="vut-wysiwyg-text"
                             v-html="product.description"
                        />
                    </UiCollapse>
                </template>

                <!--  PRODUCT ATTACHMENTS  -->
                <div v-if="product.attachments?.length"
                     class="flex flex-col gap-1"
                >
                    <h3 class="vut-h3 mb-2">
                        {{ $t('product.attachments') }}
                    </h3>

                    <div v-for="attachment in product.attachments"
                         :key="attachment.id!"
                    >
                        <a v-if="attachment.downloadUrl"
                           :href="attachment.downloadUrl"
                           download
                           class="group flex w-fit max-w-full items-center gap-3"
                        >
                            <IconFilePdf v-if="attachment.extension === 'pdf'" class="text-primary" />
                            <IconFile v-else class="text-gray" />

                            <span class="vut-attachment__name group-hover:text-secondary-darker group-hover:no-underline">
                                {{ attachment.name }}
                            </span>

                            <span class="vut-attachment__type">
                                {{ attachment.extension }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="product.getCommonProperties()?.length || product.getSku(selectedVariation)"
                 class="[grid-area:properties]"
            >
                <h2 class="vut-h2 mb-5">
                    {{ $t('product.properties') }}
                </h2>

                <div class="overflow-auto">
                    <table class="vut-product__properties-table">
                        <tbody>
                            <tr v-for="item in product.getCommonProperties()"
                                :key="item.product_property_id"
                                class=""
                            >
                                <td class="vut-product__properties-table-td md:min-w-48">
                                    {{ item.property_name }}
                                </td>
                                <td class="vut-product__properties-table-td w-full">
                                    {{ item.attribute_name }}
                                </td>
                            </tr>

                            <!-- SKU -->
                            <tr v-if="product.getSku(selectedVariation)">
                                <td class="vut-product__properties-table-td md:min-w-48">
                                    {{ $t('product.sku') }}
                                </td>
                                <td class="vut-product__properties-table-td w-full">
                                    {{ product.getSku(selectedVariation) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </BaseContainerContent>
    </div>
</template>

<script lang="ts" setup>
import type { BaseUiButton } from '#components'

const { page } = useCurrentPage()
if (!page.value?.model?.id) {
    throw createError({
        statusCode: 404,
        fatal: true,
    })
}

const { loggedIn } = useAuth()

const productUrlCopyButton = useTemplateRef<ComponentInstance<typeof BaseUiButton>>('productUrlCopyButton')

const [
    { item: product },
    { items: productImages },
    { items: productVideos },
    { items: variationsItems, status: variationsResponseStatus },
] = await Promise.all([
    useProductsApiService()
        .embed([
            ProductModel.EMBED_DISCOUNTED_TAXED_PRICE,
            ProductModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductModel.EMBED_COMPUTED_PRICES,
            ProductModel.EMBED_DISCOUNT_PERCENTS,
            ProductModel.EMBED_ATTACHMENTS,
            ProductModel.EMBED_PRODUCT_AVAILABILITY,
            ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
            ProductModel.EMBED_BRAND,
            ProductModel.EMBED_TAGS,
            ProductModel.EMBED_DEFAULT_IMAGE_URL,
            ProductModel.EMBED_SECONDARY_IMAGE_URL,
            ProductModel.EMBED_PROPERTIES,
            ProductModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST_ITEM_ID,
            ProductModel.EMBED_CUSTOMER_PRODUCT_VARIATIONS_WISH_LIST_ITEMS,
        ])
        .forId(page.value.model.id)
        .useGet(),
    useProductImagesApiService()
        .whereEquals(ProductImageModel.ATTR_PRODUCT_ID, page.value.model.id)
        .sortByAsc(ProductImageModel.ATTR_POSITION)
        .embed([
            ProductImageModel.EMBED_IMAGE_URL,
        ])
        .useGet(),
    useProductVideosApiService()
        .whereEquals(ProductVideoModel.ATTR_PRODUCT_ID, page.value.model.id)
        .sortByAsc(ProductVideoModel.ATTR_POSITION)
        .embed([
            ProductVideoModel.EMBED_IMAGE,
        ])
        .useGet(),
    useProductVariationsApiService()
        .embed([
            ProductVariationModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductVariationModel.EMBED_COMPUTED_PRICES,
            ProductVariationModel.EMBED_PROPERTIES,
            ProductVariationModel.EMBED_PRODUCT_AVAILABILITY,
            ProductVariationModel.EMBED_DISCOUNT_PERCENTS,
            ProductVariationModel.EMBED_IMAGE_URL,
        ])
        .whereEquals(ProductVariationModel.ATTR_PRODUCT_ID, page.value.model.id)
        .whereEquals(ProductVariationModel.ATTR_IS_ACTIVE, true)
        .disablePagination()
        .useGet(),
])

const amount = ref<number>(1)

const {
    variationProperties,
    selectedVariation,
    finalVariations,
    selectedAttributes,
    handlePropertyChange,
    getAvailableVariations,
    showFinalVariationSelector,
} = useProductVariationSelector(
    () => variationsItems.value ?? [],
    () => product.value ?? null
)

function copyProductUrl() {
    const url = new URL(window.location.href)
    try {
        navigator.clipboard.writeText(url.toString())
    } catch (e) {
        console.error(e)
    }
}

</script>

<style lang="scss" scoped>

.vut-product__hero {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr;
        gap: 2rem 5rem;

        grid-template-areas:
                "gallery header"
                "gallery actions";
    }
}

.vut-product__header {
    grid-area: header;
}

.vut-product__actions {
    grid-area: actions;
}

.vut-product__gallery {
    grid-area: gallery;
}

.vut-product__description {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5rem;

        grid-template-areas: "description properties"
    }
}

.vut-product__properties-table {
    width: 100%;
    border: 1px solid #ccc;
}

.vut-product__properties-table-td {
    padding: 0.75rem 1rem;
    border: 1px solid #ccc;
}

.vut-attachment__type {
    @include vut-text-small;

    border: hr-line();
    padding: 0.375rem;
    line-height: 1;
    border-radius: $vut-border-radius;
    background: $vut-c-white;
    font-family: $vut-ff-secondary;
    text-transform: uppercase;
    letter-spacing: $vut-letter-spacing-large;
}

.vut-attachment__name {
    @include vut-text-large;

    color: $vut-c-secondary;
    font-weight: bold;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    transition: color $vut-trans-time $vut-timing;
}
</style>
