<template>
    <div>
        <div class="vut-h5 mb-2">
            {{ property?.name }}
        </div>

        <LazyUiColorSelector
            v-if="property?.isTypeColor()"
            v-model="value"
            :values="property.getAttributes()"
            :is-item-disabled="(attr: any) => !availableVariations?.some(variation => variation.hasAttribute(attr as ProductVariationPropertyAttributeModel))"
            @change="handleChange"
        />

        <LazyUiAttributeSelector
            v-else-if="property?.isTypeRadio() || property?.isTypeSelect()"
            v-model="value"
            label-getter="name"
            :is-item-disabled="(attr: any) => !availableVariations?.some(variation => variation.hasAttribute(attr))"
            :values="property.getAttributes()"
            @change="handleChange"
        />

        <!-- -------------------------------------------------------------------- -->

        <!--  VARIATION SELECTOR  -->
        <!--  Selects variations instead of attributes in case of multiple variations with the same attributes  -->
        <LazyUiAttributeSelector
            v-else
            v-model="variationValue"
            :values="indistinguishableVariations ?? []"
            label-getter="name"
            @change="handleChange"
        />

        <!-- TODO - v-if env property id -->
        <NuxtLink
            v-if="property?.id === 2"
            to="TODO"
            class="mt-2 inline-block underline hover:no-underline"
        >
            {{ $t('product.size_chart') }}
        </NuxtLink>
    </div>
</template>

<script lang="ts" setup>
import type {
    ProductVariationPropertyAttributeModel
} from '@simploshop-models/custom/product-variation-property-attribute.model'
import type { VariationSelectorPropertyChangeData } from '@sim-api-composables/data-transforms'

const {
    property,
    availableVariations,
    indistinguishableVariations,
} = defineProps<{
    property?: InstanceType<typeof ProductVariationPropertyModel> | null
    availableVariations?: InstanceType<typeof ProductVariationModel>[]
    indistinguishableVariations?: InstanceType<typeof ProductVariationModel>[]
}>()

const value = defineModel<InstanceType<typeof ProductVariationPropertyAttributeModel> | null>()
const variationValue = defineModel<InstanceType<typeof ProductVariationModel> | null>('variation')

const emit = defineEmits<{
    change: [data: VariationSelectorPropertyChangeData]
}>()

function handleChange(val: InstanceType<typeof ProductVariationPropertyAttributeModel> | null) {
    if (!property) return
    emit('change', {
        property: property,
        value: val ?? null,
    })
}

</script>
