<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.56249 20C1.14809 20 0.750665 19.8025 0.457642 19.4508C0.164619 19.0992 0 18.6223 0 18.125V3.75014H1.56249V18.125H11.4582V20H1.56249ZM4.68746 16.25C4.27306 16.25 3.87564 16.0525 3.58261 15.7009C3.28959 15.3492 3.12497 14.8723 3.12497 14.375V1.87516C3.12212 1.62856 3.16141 1.38387 3.24042 1.1562C3.31942 0.928527 3.43647 0.7227 3.58434 0.551419C3.72708 0.373976 3.8986 0.233515 4.08833 0.138705C4.27805 0.0438959 4.48196 -0.00325116 4.68746 0.000174108H13.0207C13.2262 -0.00317714 13.4301 0.0440037 13.6198 0.138809C13.8095 0.233614 13.9811 0.374035 14.1238 0.551419C14.2717 0.722745 14.3887 0.928581 14.4677 1.15624C14.5467 1.3839 14.586 1.62857 14.5832 1.87516V14.375C14.5832 14.8723 14.4186 15.3492 14.1256 15.7009C13.8325 16.0525 13.4351 16.25 13.0207 16.25H4.68746ZM4.68746 14.375H13.0207V1.87516H4.68746V14.375Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
